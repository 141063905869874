import { createApp } from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import router from './router/wangyue'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-persistedstate-plugin'
import { vantInit } from '@/plugins/vant'
import { umengInit } from '@/plugins/umeng'

const pinia = createPinia()
pinia.use(createPersistedState())
const app = createApp(App)
umengInit(app)
vantInit(app)
app.use(router)
app.use(pinia)
app.mount('#app')
app.config.compilerOptions.isCustomElement = (tag) => tag.includes('wx-open-launch-app')
window.$app = app